<template>
  <div class="mypage-main-column">
    <Card title="最近見たリーチ分析">
      <DataList
        :headers="[
          { id: 'projectName', label: 'プロジェクト名', width: '20%' },
          { id: 'productList', label: '商品／ブランド', width: '15%' },
          { id: 'cmSponsorList', label: '企業', width: '13%' },
          { id: 'targetList', label: 'ターゲット', width: '8%' },
          { id: 'areaName', label: 'エリア', width: '8%' },
          { id: 'startDate', label: '開始日', width: '8%', minWidth: '108px' },
          { id: 'endDate', label: '終了日', width: '8%', minWidth: '108px' },
          { id: 'individualGrpLabel', label: '個人GRP', width: '8%' },
          { id: 'companyName', label: 'カンパニー名', width: '12%' }
        ]"
        :items="accessProjectItems"
        :loading="isLoadingAccessProjects"
        emptyMessage="最近見たリーチ分析はありません"
        fixed-header
        spacing="ss"
        max-height="443px"
        min-height="100px"
      >
        <template v-slot:projectName="{ item }">
          <Flex align-center alignFlexStart>
            <router-link
              :to="{
                name: REACH_ANALYSIS_ROUTES.result,
                params: {
                  projectId: item.projectId,
                  companyId: item.companyId
                }
              }"
            >
              <Tooltip
                :content="item.name"
                style="cursor: default"
                placement="left"
              >
                <TextLink>
                  <TypographyEllipsis color="inherit" line-height="1.5">
                    {{ item.name }}
                  </TypographyEllipsis>
                </TextLink>
              </Tooltip>
            </router-link>
          </Flex>
        </template>
        <template
          v-for="headrId in [
            'productList',
            'cmSponsorList',
            'targetList',
            'areaName',
            'startDate',
            'endDate',
            'companyName'
          ]"
          :key="'HeaderId' + headrId"
          v-slot:[headrId]="{ item }"
        >
          <Tooltip
            v-if="item !== '━'"
            :content="item"
            style="cursor: default"
            placement="left"
          >
            <TypographyEllipsis width="100%" line-height="1.5">
              {{ item }}
            </TypographyEllipsis>
          </Tooltip>
        </template>
        <template v-slot:individualGrpLabel="{ item }">
          <TypographyEllipsis width="100%" line-height="1.5" text-align="right">
            {{ item }}
          </TypographyEllipsis>
        </template>
      </DataList>
    </Card>
    <Card title="利用可能なカンパニー" class="mt-6">
      <Box>
        <TextField
          size="m"
          v-model="searchValue"
          placeholder="キーワードで絞り込む"
          class="textField"
        />
        <DataList
          :headers="[
            {
              id: 'workspaceId',
              width: '10%'
            },
            {
              id: 'companyName',
              label: 'カンパニー名',
              width: '30%'
            },
            {
              id: 'workspaceName',
              label: 'ワークスペース',
              width: '30%'
            },
            {
              id: 'companyId',
              width: '30%'
            }
          ]"
          :items="searchResult"
          :loading="userInfoStore.isLoadingJoinableCompanies"
          emptyMessage="ご利用にはカンパニーへのアクセス権が必要です。オーナーまたは管理者にお問い合わせください。"
          fixed-header
          max-height="393px"
          min-height="100px"
        >
          <template v-slot:workspaceId="{ item }">
            <Box width="40px" height="40px">
              <WorkspaceLogo
                :src="
                  companyWorkspaceLogos.find(logo => logo.id === item)?.image
                "
                class="mypage-workspace-logo"
              />
            </Box>
          </template>
          <template v-slot:companyName="item">
            <router-link
              :to="{
                name: COMPANY_ROUTES.top,
                params: {
                  companyId: searchResult[item.index].companyId.toString()
                }
              }"
            >
              <TextLink>
                <TypographyEllipsis color="inherit" line-height="1.5"
                  >{{ item.item }}
                </TypographyEllipsis>
              </TextLink>
            </router-link>
          </template>
          <template v-slot:workspaceName="workspaceName">
            <TypographyEllipsis width="100%" line-height="1.5"
              >{{ workspaceName.item }}
            </TypographyEllipsis>
          </template>
          <template v-slot:companyId="{ item }">
            <Flex align-center justify-end>
              <Button @click="goSettings(item)" color="white">設定</Button>
            </Flex>
          </template>
        </DataList>
      </Box>
    </Card>
  </div>
</template>
<script>
import { defineComponent, ref, computed } from 'vue';
import {
  CAMPAIGN_ROUTES,
  SETTINGS_ROUTES,
  COMPANY_ROUTES,
  REACH_ANALYSIS_ROUTES
} from '@/router';
import Flex from '@/components/layout/Flex.vue';
import Card from '@/components/ui/Card.vue';
import WorkspaceLogo from '@/components/ui/WorkspaceLogo.vue';
import { fetchWorkspaceLogo } from '@/composables/fetchImage';
import { useMyPage, useAccessProjects } from '@/composables/mypage';
import { useUserInfoStore } from '@/store/userInfo';
import Box from '@/components/layout/Box';
import Button from '@/components/ui/Button';
import DataList from '@/components/ui/DataList';
import TextLink from '@/components/ui/TextLink.vue';
import TypographyEllipsis from '@/components/ui/TypographyEllipsis.vue';
import TextField from '@/components/ui/TextField';
import Tooltip from '@/components/ui/Tooltip.vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'mypageTop',
  components: {
    TypographyEllipsis,
    TextField,
    Button,
    Card,
    Box,
    DataList,
    Flex,
    WorkspaceLogo,
    Tooltip,
    TextLink
  },
  setup() {
    const userInfoStore = useUserInfoStore();
    const { selectCompany, selectWorkspace } = useMyPage();
    const workspaceLogos = ref([]);
    const companyWorkspaceLogos = ref([]);
    const searchValue = ref('');

    const router = useRouter();

    const searchResult = computed(() => {
      return userInfoStore.joinableCompanies.filter(user => {
        return (
          user.companyName.includes(searchValue.value) ||
          user.workspaceName.includes(searchValue.value)
        );
      });
    });

    const goSettings = item => {
      router.push({
        name: SETTINGS_ROUTES.brand,
        params: { companyId: item.toString() }
      });
    };

    userInfoStore.fetchJoinableCompanies();
    userInfoStore.$subscribe(() => {
      const _workspaces = userInfoStore.userInfo.workspaces;
      for (const workspace of _workspaces) {
        fetchWorkspaceLogo(workspace.workspaceId.toString()).then(image => {
          workspaceLogos.value = [
            ...workspaceLogos.value,
            { id: workspace.workspaceId, image }
          ];
        });
      }

      const _companies = userInfoStore.joinableCompanies;
      for (const company of _companies) {
        fetchWorkspaceLogo(company.workspaceId.toString()).then(image => {
          companyWorkspaceLogos.value = [
            ...companyWorkspaceLogos.value,
            { id: company.workspaceId, image }
          ];
        });
      }
    });

    const {
      accessProjectItems,
      isLoadingAccessProjects,
      setAccessProjects,
      onClickFavorite
    } = useAccessProjects();

    setAccessProjects(userInfoStore.user.id);

    return {
      userInfoStore,
      workspaceLogos,
      CAMPAIGN_ROUTES,
      SETTINGS_ROUTES,
      COMPANY_ROUTES,
      REACH_ANALYSIS_ROUTES,
      companyWorkspaceLogos,
      selectCompany,
      selectWorkspace,
      accessProjectItems,
      isLoadingAccessProjects,
      onClickFavorite,
      searchValue,
      searchResult,
      goSettings
    };
  }
});
</script>
<style lang="scss">
@import '@/styles/spacing';
@import '@/styles/effect';

$righ-content-width: 30%;

.workspace-list {
  height: 100%;
  overflow: hidden;
}

.mypage-workspace-account {
  :hover {
    cursor: default;
  }
}

.mypage-workspace-item {
  padding: $spacing-base * 3;
  border: 1px solid var(--dark-10-hex-color);
  border-radius: 8px;

  &:not(:last-child) {
    margin-bottom: $spacing-base * 2;
  }
}

.mypage-main-column {
  flex: 1;
}

.mypage-right-column {
  width: $righ-content-width;
}

.mypage-company-item {
  &:not(:first-child) {
    border-top: 1px solid var(--dark-10-hex-color);
  }

  &-names {
    flex: 1;
  }

  &-name,
  &-name &-workspace-name {
    word-break: break-all;
  }
}

.mypage-workspace-logo .ui-image.no-image {
  background-size: 72px auto;
  background-position-y: 8px;
}

.textField {
  width: 250px;
}
</style>
